import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

/**
 * DiscordJoinButton Component
 * - Renders a button with Discord icon and "Join Us" text.
 * - Utilizes FontAwesome for the Discord icon.
 * - Applies Tailwind CSS for styling.
 */
const DiscordJoinButton: React.FC = () => {
  /**
   * handleClick
   * - Handles click event on the button.
   * - Could be used to redirect to Discord invite link or perform other actions.
   */
  /**
   * Redirects to the specified Discord invite link upon button click.
   */
  const handleClick = () => {
    window.open('https://discord.gg/4XJDwTM4U4', '_blank');
  };

  return (
    <button
      className="flex items-center justify-center px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faDiscord} beat />
      <span className="ml-2">Join Us</span>
    </button>
  );
};

export default DiscordJoinButton;