import React from 'react';
import { useAuth } from '../../context/authContext';
import googleIcon from '../../assets/continueWithGoogleButton.svg';

const LoginButton: React.FC = () => {
  const { signInWithGoogle } = useAuth();

  return (
    <button onClick={signInWithGoogle}>
      <img src={googleIcon} alt="Sign in with Google" />
    </button>
  );
};

export default LoginButton;