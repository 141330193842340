import React from 'react';
import { AuthProvider, useAuth } from './context/authContext';
import useFirebaseAuth from './hooks/useFirebaseAuth';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; // Added import for Privacy Policy Page
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Added import for React Router

const App: React.FC = () => {
  const { authToken } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/" element={authToken ? <HomePage /> : <LoginPage />} />
      </Routes>
    </Router>
  );
};

export default App;
