import React from 'react';
import { formatDate } from '../utils/formatDate'; // Import the utility function

interface EventDetailsProps {
  eventDetails: {
    summary?: string;
    description?: string;
    start?: string;
    end?: string;
    time_zone?: string;
    creator_email?: string;
    link?: string;
  };
}

const EventDetails: React.FC<EventDetailsProps> = ({ eventDetails }) => {
  if (!eventDetails) return null;

  return (
    <div className="p-4 border rounded-lg shadow space-y-2  mx-auto">
      <h3 className="text-lg font-semibold text-center">Event Details</h3>
      {eventDetails.summary && <p><strong>Summary:</strong> {eventDetails.summary}</p>}
      {eventDetails.description && <p><strong>Description:</strong> {eventDetails.description}</p>}
      {eventDetails.start && <p><strong>Start:</strong> {formatDate(eventDetails.start)}</p>}
      {eventDetails.end && <p><strong>End:</strong> {formatDate(eventDetails.end)}</p>}
      {eventDetails.time_zone && <p><strong>Time Zone:</strong> {eventDetails.time_zone}</p>}
      {eventDetails.creator_email && <p><strong>Creator Email:</strong> {eventDetails.creator_email}</p>}
      {eventDetails.link && <a href={eventDetails.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 block text-center mt-4">View in calendar</a>}
    </div>
  );
};

export default EventDetails;