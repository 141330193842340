import React from 'react';
import { useTextInputWithSubmit } from '../hooks/useTextInputWithSubmit';
import SubmitButton from './SubmitButton';
import TextInputField from './TextInputField';
import EventDetails from './EventDetails';

interface TextInputWithSubmitProps {
  className?: string;
}

const TextInputWithSubmit: React.FC<TextInputWithSubmitProps> = ({ className }) => {
  const { inputValue, handleInputChange, handleSubmit, isLoading, eventDetails } = useTextInputWithSubmit();

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isLoading && inputValue.trim() !== '') {
      handleSubmit();
    }
  };

  // Adjusted style for better responsiveness and centering
  const containerStyle = `max-w-xl mx-auto p-4 ${className}`;

  return (
    <div className={containerStyle} onKeyPress={handleKeyPress}>
      <div className="flex items-center justify-center space-x-2">
        <TextInputField
          value={inputValue}
          onChange={handleInputChange}
          disabled={isLoading}
        />
        <SubmitButton onClick={handleSubmit} disabled={isLoading || inputValue.trim() === ''} />
      </div>
      {isLoading && <div className="flex justify-center items-center mt-2"><p className="animate-pulse">Loading...</p></div>}
      {eventDetails && <EventDetails eventDetails={eventDetails} />}
    </div>
  );
};

export default TextInputWithSubmit;