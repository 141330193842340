import React from 'react';
import TextInputWithSubmit from '../components/TextInputWithSubmit';
import SignOutButton from '../components/auth/SignOutButton';
import Header from '../components/Header';
import Footer from '../components/Footer'; // Import the Footer component

const HomePage: React.FC = () => {
  return (
    <div className="font-sans flex flex-col items-center justify-center h-screen">
      <Header/>
      <div className="flex flex-col items-center justify-center mt-20">
        <TextInputWithSubmit className="mb-4" />
      </div>
      <div className="mt-auto mb-4">
        <SignOutButton />
      </div>
      <Footer /> {/* Use the Footer component */}
    </div>
  );
};

export default HomePage;