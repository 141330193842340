import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/firebase-config';
import { useAuth } from '../../context/authContext';

const SignOutButton: React.FC = () => {
  const { setAuthToken } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthToken(null); // Reset the auth token in context
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <button onClick={handleSignOut} className="font-sans bg-red-400 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full">Log Out</button>
  );
};

export default SignOutButton;