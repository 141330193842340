import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="p-4">
      <h1>Privacy Policy for CalGPT</h1>
      <p>At CalGPT, accessible from https://calgpt.app/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by CalGPT and how we use it.</p>
      
      <h2>Consent</h2>
      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
      
      <h2>Information We Collect</h2>
      <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
      <p>If you choose to use our app, we will require access to your Google Calendar. This includes:</p>
      <ul>
        <li>The ability to add events to your Google Calendar.</li>
        <li>Access to manage your events on Google Calendar.</li>
      </ul>
      <p>This access allows CalGPT to provide its functionalities, such as adding events to your calendar through our app.</p>
      
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website and app.</li>
        <li>Improve, personalize, and expand our website and app.</li>
        <li>Understand and analyze how you use our website and app.</li>
        <li>Develop new products, services, features, and functionality.</li>
        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website and app, and for marketing and promotional purposes.</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicyPage;