import React from 'react';
import DiscordJoinButton from './DiscordJoinButton';

/**
 * Footer Component
 * - Contains the DiscordJoinButton.
 * - Designed to be used across various pages for consistency.
 */
const Footer: React.FC = () => {
  return (
    <div className="w-full py-4 flex justify-center items-center bg-gray-100 mt-auto">
      <DiscordJoinButton />
    </div>
  );
};

export default Footer;