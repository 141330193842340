import React from 'react';
import UserCircleIcon from './user/UserCircleIcon'; // Importing the UserCircleIcon component

/**
 * Displays the application's header.
 * - Centers the title "CalGPT" above the subtitle.
 * - Uses flexbox to align items centrally and manage layout.
 */
const Header: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center relative">
      <div className="text-center">
        <h1 className="text-6xl mb-2">Cal<span className="font-bold">GPT</span></h1>
        <h2 className="text-xl font-bold mb-4">Schedule Google Calendar events with natural language</h2>
      </div>
      <div className="absolute right-0 top-0 m-4 flex items-center">
        {/* Assuming another icon exists here, UserCircleIcon is added to the right of it */}
        <div className="mr-10">{/* Existing icon */}</div>
        {/* <UserCircleIcon className="ml-4" /> UserCircleIcon added to the right of the existing icon with increased spacing */}
      </div>
    </div>
  );
};

export default Header;