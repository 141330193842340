import { GoogleAuthProvider, onAuthStateChanged, signInWithRedirect, getRedirectResult, User } from "firebase/auth";
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { auth } from '../firebase/firebase-config'; 


interface AuthContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  userPhotoURL: string | null; // New state for the user's photo URL
  setUserPhotoURL: (url: string | null) => void; // Function to update the user's photo URL
  signInWithGoogle: () => Promise<void>;
}
  

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'));
    const [user, setUser] = useState<User | null>(null);
    const [userPhotoURL, setUserPhotoURL] = useState<string | null>(null); // State for the user's photo URL

    const signInWithGoogle = async () => {

      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/calendar');
      provider.addScope('https://www.googleapis.com/auth/calendar.events');

      try {
        await signInWithRedirect(auth, provider);
      } catch (error) {
        if (error instanceof Error) { // Type guard to narrow down the type
          console.error("Error during signInWithRedirect:", error);
          alert(`Login failed: ${error.message}`);
        } else {
          // Handle cases where the caught error is not an instance of Error
          console.error("An unexpected error occurred:", error);
          alert("Login failed due to an unexpected error.");
        }
      }
  };

      useEffect(() => {
        getRedirectResult(auth)
            .then((result) => {
                if (result) { // Check if result is not null
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    console.log('Credential:', credential);

                    const token = credential?.accessToken;
                    if (token) {
                        localStorage.setItem('authToken', token); // Persist token
                        setAuthToken(token); // Set the token globally using the context
                        console.log('Token set:', token);
                    }
                    // If sign-in was successful, the User information will be in the result
                    const user = result.user;
                    if (user) {
                      console.log('User is signed in:', user);
                        setUser(user);
                        setUserPhotoURL(user.photoURL); // Update the user's photo URL
                    }
                }
            })
            .catch((error) => {
                console.error("Error during getRedirectResult:", error);
                alert(`Login process failed: ${error.message}`);
            });

        const unsubscribe = onAuthStateChanged(auth, currentUser => {
          if (currentUser) {
            console.log('User is signed in:', currentUser);
            setUser(currentUser);
            setUserPhotoURL(currentUser.photoURL); // Update the user's photo URL
          } else {
            console.log('User is signed out');
            setUser(null);
            setAuthToken(''); // Reset the auth token
            setUserPhotoURL(null); // Reset the user's photo URL
            localStorage.removeItem('authToken'); // Clear token from storage
          }
        });

        return () => unsubscribe();
      }, [setUser, setAuthToken, setUserPhotoURL]);

      return (
        <AuthContext.Provider value={{ user, setUser, authToken, setAuthToken, userPhotoURL, setUserPhotoURL, signInWithGoogle }}>
          {children}
        </AuthContext.Provider>
      );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};