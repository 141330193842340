// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzVF_IRLLKj_tcTUpXRy5DegsrWtfHZdo",
  authDomain: "calgpt.app",
  projectId: "calgpt-id",
  storageBucket: "calgpt-id.appspot.com",
  messagingSenderId: "317742295630",
  appId: "1:317742295630:web:67ef12f0f3c968c204311f",
  measurementId: "G-HP2FZ2M7MK"
};

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase_app);
export const auth = getAuth(firebase_app);
export const provider = new GoogleAuthProvider();

export default {
  firebase_app,
  analytics,
  auth,
  provider,
};

