import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/authContext';

// Function to get user's timezone
const getUserTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const useTextInputWithSubmit = () => {
  const { authToken } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [calEventUrl, setCalEventUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState<any>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (!authToken) {
      console.error('No auth token available');
      return;
    }
    setIsLoading(true);
    const postData = {
      accessToken: authToken,
      textPrompt: inputValue,
      timeZone: getUserTimezone(), // Dynamically include the user's timezone
    };

    try {
      const response = await axios.post('https://process-text-with-ai-uuh2jm3f5q-uc.a.run.app', postData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200 && response.data) {
        // Assuming response.data contains the event details
        setCalEventUrl(response.data.link); // Set the calendar event URL
        setEventDetails(response.data); // Store all event details received from the API
      }
      setInputValue(''); // Reset input field after submit
    } catch (error) {
      console.error('Error submitting text:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { inputValue, handleInputChange, handleSubmit, calEventUrl, isLoading, eventDetails };
};