import React from 'react';

interface SubmitButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`ml-2 w-10 h-10 rounded-full flex items-center justify-center ${disabled ? 'bg-gray-300' : 'bg-blue-500 hover:bg-blue-700'} text-white`}
    >
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    </button>
  );
};

export default SubmitButton;