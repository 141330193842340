import React from 'react';
import LoginButton from '../components/auth/LoginButton';
import Header from '../components/Header';
import Footer from '../components/Footer'; // Import the Footer component

const LoginPage: React.FC = () => {
  return (
    <div className="font-sans flex flex-col items-center justify-center h-screen">
      <Header />
      <p className="text-center mb-4">
        Powered by GPT-4.
      </p>
      <LoginButton/>
      <Footer /> {/* Use the Footer component */}
    </div>
  );
};

export default LoginPage;