import React from 'react';

interface TextInputFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

const TextInputField: React.FC<TextInputFieldProps> = ({ value, onChange, disabled }) => {
  return (
    <div className="p-4"> {/* Added outer padding using Tailwind CSS */}
      <input
        type="text"
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder="Ex. I got a Meeting in 3 hours at 123 sesame street"
        className="font-sans shadow appearance-none border rounded-full w-full py-3 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        // Apply default style directly here
      //   style={{ width: '100%', fontFamily: 'sans-serif', flexGrow: 1 }}
      />
    </div>
  );
};

export default TextInputField;
