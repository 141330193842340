/**
 * Converts an ISO 8601 date string into a human-readable format.
 * @param {string} isoString - The ISO 8601 date string to format.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (isoString) => {
    if (!isoString) return "";

    const date = new Date(isoString);
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
    };
    return new Intl.DateTimeFormat("default", options).format(date);
};
